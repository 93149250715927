@import "react-day-picker/lib/style.css";
@tailwind base;
@tailwind components;

.DayPicker-Day {
  display: table-cell;
  text-align: center;
  cursor: pointer;
  @apply rounded-full p-2 w-10 h-10 align-middle;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  @apply bg-brand-green text-white;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  @apply bg-brand-green bg-opacity-60;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  @apply bg-brand-green bg-opacity-20;
}

.DayPicker-Day--today {
  @apply font-medium text-brand-green-dark;
}

.DayPickerInput-OverlayWrapper {
  /* position: relative; */
  /* position: fixed; */
  position: absolute;
  min-width: 440px;
  z-index: 900;
}

.brand-link {
  @apply text-brand-green underline text-sm;
}

.brand-link:hover {
  @apply opacity-60;
}

.brand-button {
  @apply rounded-xl py-2 px-3 text-sm font-medium bg-brand-green text-white;
}

.brand-button:hover {
  @apply bg-opacity-60;
}

.brand-button:disabled {
  @apply bg-gray-200 text-gray-400;
}

.brand-button-secondary {
  @apply rounded-xl py-2 px-3 text-sm font-medium bg-brand-green bg-opacity-10 text-brand-green;
  /* background-color: rgba(190, 190, 50, 0.1); */
}

.brand-button-secondary:hover {
  @apply bg-opacity-5;
}

.brand-button-secondary:disabled {
  @apply bg-gray-200 text-gray-400;
}

.brand-button-tertiary {
  @apply rounded-xl py-2 px-3 text-sm font-medium bg-white border-2 text-gray-500 border-gray-500;
}

.brand-button-tertiary:hover {
  @apply text-brand-green border-brand-green;
}

.brand-button-tertiary:disabled {
  @apply border-gray-200 text-gray-200;
}

.brand-badge {
  @apply inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium bg-brand-green text-white;
}

.brand-badge:hover {
  @apply opacity-60;
}

.brand-badge-secondary {
  @apply inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium bg-brand-green bg-opacity-10 text-brand-green;
}

.brand-badge-secondary:hover {
  @apply bg-opacity-5;
}

.sticky-left {
  position: sticky;
  left:-5px;
  background-color: inherit;
}
.sticky-right {
  position: sticky;
  right:-5px;
  background-color: inherit;
}
@tailwind utilities;

.page-break-before {
  page-break-before: always;
}

@media print {
  .page-break-before {
    page-break-before: always;
  }
  @page {
    size: A3;
  }
}
